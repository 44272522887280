import { clsx } from '@digital-spiders/misc-utils';
import React from 'react';
import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import Image from '../Image';
import ModuleLayout from '../ModuleLayout';
import * as styles from './BeforeAndAfterModule.module.scss';

export type BeforeAndAfterModuleProps = {
  backgroundColor: ModuleBackgroundColor;
  title: string;
  beforeAndAfterItems: Array<{
    beforeImage: SanityImageType;
    beforeVideoViews: string;
    afterImage: SanityImageType;
    afterVideoViews: string;
  }>;
  className?: string;
};

export function getModuleBgColor(props: BeforeAndAfterModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

function BeforeAndAfterModule(
  props: BeforeAndAfterModuleProps & CommonModuleProps,
): React.ReactElement {
  const { title, beforeAndAfterItems, className, moduleId, previousModuleBgColor } = props;

  function renderItemContainer(
    step: 'before' | 'after',
    image: SanityImageType,
    videoViews: string,
  ) {
    return (
      <div className={clsx(styles.itemContainer, step === 'before' ? styles.before : styles.after)}>
        <div className={styles.itemTitle}>{step}</div>
        <div className={styles.itemImageContainer}>
          <Image image={image} className={styles.itemImage} height={370} width={320} cover />
        </div>
        <div className={styles.itemTitle}>{videoViews}</div>
      </div>
    );
  }

  return (
    <ModuleLayout
      id={moduleId}
      className={className}
      title={title}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
      childrenClassName={styles.content}
    >
      {beforeAndAfterItems.map((item, index) => (
        <div key={index} className={styles.beforeAndAfterContainer}>
          {renderItemContainer('before', item.beforeImage, item.beforeVideoViews)}
          {renderItemContainer('after', item.afterImage, item.afterVideoViews)}
        </div>
      ))}
    </ModuleLayout>
  );
}

export default BeforeAndAfterModule;
