import { clsx } from '@digital-spiders/misc-utils';
import React, { ReactNode } from 'react';
import arrow from '../../images/button_arrow.svg';

import * as styles from './Button.module.scss';

export interface ButtonProps extends Omit<React.HTMLAttributes<HTMLElement>, 'children'> {
  children: string | ReactNode;
  outlined?: boolean;
  backgroundColor?: 'white';
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: 'submit';
  withoutIconAnimation?: boolean;
  largeButton?: boolean;
  subtitle?: string;
  className?: string;
}

const Button = ({
  children,
  outlined,
  backgroundColor,
  onClick,
  type,
  withoutIconAnimation,
  largeButton,
  subtitle,
  className,
  ...restProps
}: ButtonProps): React.ReactElement => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        styles.button,
        outlined && styles.outlined,
        backgroundColor === 'white' && styles.white,
        withoutIconAnimation && styles.withoutIconAnimation,
        largeButton && styles.largeButton,
        subtitle && styles.withSubtitle,
        className,
      )}
      type={type === 'submit' ? 'submit' : 'button'}
      {...restProps}
    >
      <div className={styles.children}>
        {children}
        {largeButton && backgroundColor === 'white' && (
          <img src={arrow} alt="" className={styles.arrow} />
        )}
      </div>
      {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
    </button>
  );
};

export default Button;
