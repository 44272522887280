import { clsx } from '@digital-spiders/misc-utils';
import React, { useRef } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import { usePagination } from '../../../utils/hooks';
import { wrapSquareBracketedWithEm } from '../../../utils/utils';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import Image from '../Image';
import ModuleLayout from '../ModuleLayout';
import * as styles from './ImageGridModule.module.scss';

export interface ImageGridModuleProps {
  backgroundColor: ModuleBackgroundColor;
  title: string;
  images: Array<{
    title?: string;
    image: SanityImageType;
  }>;
  withPagination?: boolean;
  itemsPerPage?: number;
  className?: string;
}

export function getModuleBgColor(props: ImageGridModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

function ImageGridModule(props: ImageGridModuleProps & CommonModuleProps): React.ReactElement {
  const {
    backgroundColor,
    title,
    images,
    withPagination,
    itemsPerPage,
    className,
    moduleId,
    previousModuleBgColor,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  const { currentPageItems, loading, renderPagination } = usePagination(
    images,
    itemsPerPage || 6,
    containerRef,
  );

  return (
    <ModuleLayout
      id={moduleId}
      className={className}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
      title={title}
    >
      <div className={clsx(styles.imagesContainer, loading && styles.loading)} ref={containerRef}>
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 720: 2, 960: 3 }}>
          <Masonry gutter="24px">
            {(withPagination ? currentPageItems : images).map((image, i) => (
              <div className={styles.imageContainer} key={i}>
                {image.title && (
                  <p className={styles.imageTitle}>{wrapSquareBracketedWithEm(image.title)}</p>
                )}
                <Image image={image.image} className={styles.image} />
              </div>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>
      {withPagination && renderPagination()}
    </ModuleLayout>
  );
}

export default ImageGridModule;
