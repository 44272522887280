import { graphql, useStaticQuery } from 'gatsby';
import groq from 'groq';
import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import PreviewLoadingScreen from '../../../preview/PreviewLoadingScreen';
import { usePreviewData } from '../../../preview/previewUtils';
import { PageDocument } from '../../../types/types';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import Calendar from '../Calendar';
import ModuleLayout from '../ModuleLayout';
import * as styles from './CalendarModule.module.scss';

export type CalendarModuleProps = {
  title: string;
  calendaId?: string;
  pageToGoAfterSubmit?: PageDocument;
  className?: string;
};

interface QueryData {
  sanityHpWebsiteSettings: {
    defaultScheduleOnceCalendarId: string;
  };
}

export function getModuleBgColor(props: CalendarModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return 'red';
}

function CalendarModule(props: CalendarModuleProps & CommonModuleProps): React.ReactElement {
  const staticData = useStaticQuery<QueryData>(graphql`
    {
      sanityHpWebsiteSettings {
        defaultScheduleOnceCalendarId
      }
    }
  `);

  const groqQuery = groq`{
    "sanityHpWebsiteSettings": {
        ...
    }
}`;

  const data = usePreviewData<QueryData>(staticData, {
    groqQuery,
  });

  if (!data) {
    return <PreviewLoadingScreen></PreviewLoadingScreen>;
  }

  const { title, calendaId, pageToGoAfterSubmit, className, moduleId, previousModuleBgColor } =
    props;

  return (
    <ModuleLayout
      id={moduleId}
      className={className}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
      childrenClassName={styles.content}
    >
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.calendarContainer}>
        <div className={styles.loadingCalendar}>
          <FaSpinner className={styles.loadingIcon} />
          <span className={styles.loadingLabel}>Loading calendar...</span>
        </div>
        <Calendar
          className={styles.calendar}
          pageToGoAfterSubmit={pageToGoAfterSubmit}
          defaultCalendarId={
            calendaId || data.sanityHpWebsiteSettings.defaultScheduleOnceCalendarId
          }
        />
      </div>
      <div className={styles.redBackground}></div>
      <div className={styles.whiteSpace}></div>
    </ModuleLayout>
  );
}

export default CalendarModule;
