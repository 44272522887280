// extracted by mini-css-extract-plugin
export var calendar = "CalendarModule-module--calendar--989a5";
export var calendarContainer = "CalendarModule-module--calendarContainer--8f31f";
export var content = "CalendarModule-module--content--3fed8";
export var loadingCalendar = "CalendarModule-module--loadingCalendar--782bb";
export var loadingIcon = "CalendarModule-module--loadingIcon--2203d";
export var loadingLabel = "CalendarModule-module--loadingLabel--6cec6";
export var redBackground = "CalendarModule-module--redBackground--8d678";
export var rotate = "CalendarModule-module--rotate--30aff";
export var title = "CalendarModule-module--title--e9290";
export var whiteSpace = "CalendarModule-module--whiteSpace--eba78";