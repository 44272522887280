import { clsx } from '@digital-spiders/misc-utils';
import { easeOut, motion, useScroll, useTransform } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import groq from 'groq';
import React, { useContext, useRef } from 'react';
import { LocationContext } from '../../../contexts/LocationContext';
import { ButtonLinkType } from '../../../graphql-fragments/ButtonLink';
import PreviewLoadingScreen from '../../../preview/PreviewLoadingScreen';
import { usePreviewData } from '../../../preview/previewUtils';
import { getUrlFromVersatileLink } from '../../../utils/sanity';
import { replaceNewLinesWithBr } from '../../../utils/utils';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import ButtonLink from '../ButtonLink';
import { getModuleId } from '../ModuleLayout';
import * as styles from './CTAModule.module.scss';

export type CTAModuleProps = {
  title?: string;
  text?: string;
  button?: ButtonLinkType;
  buttonSubtitle?: string;
  rowLayout?: boolean;
  withPaddingBottom?: boolean;
  className?: string;
};

interface QueryData {
  sanityHpWebsiteSettings: {
    scheduleACallModule: {
      title: string;
      text: string;
      button: ButtonLinkType;
    };
    header: {
      ctaButtonAnchor?: string;
    };
  };
}

export function getModuleBgColor(props: CTAModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return 'red';
}

function CTAModule(props: CTAModuleProps & CommonModuleProps): React.ReactElement {
  const sectionRef = useRef<HTMLDivElement>(null);

  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ['start 120%', 'start 0%'],
  });

  const backgroundTranslateYMV = useTransform(scrollYProgress, [0, 1], ['20vh', '0vh'], {
    ease: easeOut,
  });
  const contentTranslateYMV = useTransform(scrollYProgress, [0, 1], ['-15vh', '0vh'], {
    ease: easeOut,
  });

  const staticData = useStaticQuery<QueryData>(graphql`
    {
      sanityHpWebsiteSettings {
        scheduleACallModule {
          title
          text
          button {
            ...ButtonLink
          }
        }
        header {
          ctaButtonAnchor
        }
      }
    }
  `);

  const groqQuery = groq`{
    "sanityHpWebsiteSettings": *[_id == "hp-website-settings-singleton"][0] {
      scheduleACallModule {
        ...
      }
      header {
        ctaButtonAnchor
      },
    }
  }`;

  const data = usePreviewData<QueryData>(staticData, {
    groqQuery,
  });

  if (!data) {
    return <PreviewLoadingScreen></PreviewLoadingScreen>;
  }

  const {
    scheduleACallModule: defaultCtaSection,
    header: { ctaButtonAnchor },
  } = data.sanityHpWebsiteSettings;

  const {
    title,
    text,
    button,
    buttonSubtitle,
    rowLayout,
    withPaddingBottom,
    className,
    moduleId,
    previousModuleBgColor,
  } = props;

  const location = useContext(LocationContext);
  const defaultCtaButtonUrl =
    defaultCtaSection.button &&
    (ctaButtonAnchor &&
    location?.pathname?.replace(/\/+$/, '') ===
      getUrlFromVersatileLink(defaultCtaSection.button).replace(/\/+$/, '')
      ? '#' + ctaButtonAnchor.replace('#', '')
      : getUrlFromVersatileLink(defaultCtaSection.button));

  return (
    <div
      ref={sectionRef}
      className={clsx(
        styles.container,
        rowLayout && styles.rowLayout,
        rowLayout && withPaddingBottom && styles.withPaddingBottom,
        className,
      )}
      style={
        !rowLayout
          ? {
              background:
                (previousModuleBgColor === 'dark' && 'var(--color-dark-blue)') ||
                (previousModuleBgColor === 'light' && 'var(--color-white)') ||
                'var(--surface-color)',
            }
          : undefined
      }
    >
      <div id={getModuleId(moduleId, title)} className={styles.scrollAnchor}></div>
      {!rowLayout && (
        <motion.div
          className={styles.background}
          style={{ y: backgroundTranslateYMV }}
        ></motion.div>
      )}
      <motion.div
        className={styles.content}
        style={!rowLayout ? { y: contentTranslateYMV } : undefined}
      >
        <div className={styles.textContainer}>
          <h2 className={styles.title}>{title || defaultCtaSection.title}</h2>
          <p className={styles.text}>{replaceNewLinesWithBr(text || defaultCtaSection.text)}</p>
        </div>
        <ButtonLink
          to={button || { url: defaultCtaButtonUrl }}
          className={styles.button}
          backgroundColor="white"
          largeButton
          subtitle={buttonSubtitle}
        >
          {button?.title || defaultCtaSection.button.title}
        </ButtonLink>
      </motion.div>
    </div>
  );
}

export default CTAModule;
